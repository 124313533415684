<template>
  <div>
    <div v-show="host">
      <v-btn plain to="/stock/dashboard"> <v-icon left>mdi-view-dashboard-variant-outline</v-icon>Dashboard </v-btn>
      <v-btn plain to="/stock/allcodes"> <v-icon left>mdi-file-find-outline</v-icon>Stocks </v-btn>
      <v-btn plain to="/stock/gallery"> <v-icon left>mdi-image-multiple</v-icon>Galería </v-btn>
    </div>
    <v-divider></v-divider>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "gasto",
  data() {
    return {
      host: false,
    };
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      this.$router.push("/login");
    } else if (user.username !== "invitado") {
      this.host = true;
    }
  },
};
</script>
